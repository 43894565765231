import { v4 as uuidV4 } from 'uuid';
import { IAchievementGetDTO, IAchievementPostDTO } from 'dorian-shared/types/achievement/Achievement';
import { StepCheckOperatorType } from '../Book/MemoryBank/memoryBankTypes';
import { IAchievementModal } from './AchievementsModalTypes';

export const newAchievement = (id: number|string = uuidV4()): IAchievementModal => ({
  id,
  name: '',
  description: '',
  displayName: '',
  icon: '',
  isAdded: true,
  isManual: false,
  check: {
    variableId: 0,
    operator: StepCheckOperatorType.AtLeast,
    value: '',
  },
  usedInStory: [],
});

export const convertAchievementToPostDTO = (achievement: IAchievementModal | IAchievementGetDTO): IAchievementPostDTO => ({
  name: achievement.name,
  icon: achievement.icon,
  displayName: achievement.displayName,
  description: achievement.description,
  isManual: achievement.isManual,
  check: { ...achievement.check },
});

export const convertAchievementGetDTOToModal = (achievement: IAchievementGetDTO): IAchievementModal => ({
  id: achievement.id,
  name: achievement.name,
  icon: achievement.icon,
  displayName: achievement.displayName,
  description: achievement.description,
  isManual: achievement.isManual,
  check: {
    ...achievement.check,
    value: Number.isNaN(achievement.check.value) ? 0 : Number(achievement.check.value),
  },
  isAdded: false,
  usedInStory: achievement.usedInStory,
});
