import arrayMove from 'array-move';
import async from 'async';
import classNames from 'classnames/bind';
import serialize from 'form-serialize';
import { isEqual } from 'lodash-es';
import React, { Component } from 'react';
import {
  Button, Col, Form, ListGroup, Modal, Row, Spinner,
} from 'react-bootstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { showToast } from 'components/ui/utils';
import { StepTypeMode } from '../../../../dorian-shared/types/branch/Branch';
import {
  BranchStepMemoryActionType,
  StepTypes,
} from '../../../../dorian-shared/types/branch/BranchStep';
import { noop } from '../../../../helpers/noop';
import { api } from '../../../api';
import { ErrorAlert } from '../../../ui/Errors/ErrorAlet';
import { isDecisionStep, isStepTypeIdExist } from '../../../utils/branchUtils';
import {
  getMemorySlotVariableLink,
  isMemorySlotVariableLink,
  parseMemorySlotVariableLink,
} from '../../../utils/choiceMemoryUtils';
// eslint-disable-next-line import/no-cycle
import { PremiumIpDisabledApprovedEdit } from '../../../utils/premiumIpDisabledApprovedEdit';
import { PremiumIpDisabledEdit } from '../../../utils/premiumIpDisabledEdit';
import {
  CheckOperator,
  MemoryCheckValueType,
  MemoryType,
} from '../../Book/MemoryBank/memoryBankTypes';
import { CustomSelect } from '../CustomSelect';
import { AddBranchHead } from './AddBranchHead';
import { AddStep } from './AddStep';
import { BranchModalPrevNext } from './BranchModalPrevNext';
import { ContextProvider } from './context/ContextProvider';
import { EliminationAnswerResultType } from './Steps/AnswerEliminationType';
import { clearStepData, getStepData, prepareForCopy } from './Steps/CopyPastStep';
import styles from './Steps/Steps.scss';
import { StepsForm } from './Steps/StepsForm';
import { SWITCH_INDEX_TYPE } from './Steps/StepTypeCheck/StepTypeCheckSwitchForm';
import { RememberActionType } from './Steps/StepTypeRemember';
import { checkUsedMemoriesInText, count, SCROLLABLE_STEP_FORM_ELEMENT_ID } from './Steps/utils';

const cs = classNames.bind(styles);

const SortableItemSteps = SortableElement(({ object, i, ...props }) => (
  <StepsForm
    step={object}
    index={i}
    {...props}
  />
));

const SortableListSteps = SortableContainer(({ items, disabledSortable, ...props }) => (
  <ListGroup as="ol" start="0" variant="flush" className="my-1 ml-4 mr-1 branchesList">
    {items.map((value, index) => (
      <SortableItemSteps
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        disabled={!!disabledSortable}
        index={index}
        object={value}
        i={index}
        {...props}
      />
    ))}
  </ListGroup>
));

// eslint-disable-next-line no-return-assign,no-void,no-cond-assign,no-param-reassign
const replacer = (k, v) => (Array.isArray(v) && !(v = v.filter((e) => e)).length ? void 0 : v);

export class AddBranch extends Component {
  constructor(...args) {
    super(...args);
    const { data } = this.props;

    this.state = {
      validated: false,
      locations: [],
      locationId: '',
      steps: [],
      stepConst: [],
      stepsType: [],
      characters: [],
      characterExpression: [],
      tagsTypes: [],
      modifiersTypes: [],
      requiresTypes: [],
      traitsTypes: [],
      // eslint-disable-next-line react/no-unused-state
      addStep: false,
      // eslint-disable-next-line react/no-unused-state
      analytics: null,
      loading: false,
      errorMsg: null,
      saveLoading: false,
      activeSteps: null,
      addLoading: false,
      choice: false,
      isCheckStep: false,
      gotoBranchId: data ? data.gotoBranchId : '',
      modalPrev: false,
      modalNext: false,
      defaultValueTitle: data ? data.title : 'NewNode',
      defaultValueDescription: data ? data.description || '' : 'Description',
      answerType: [],
      statsInfo: {},
      currStatsInfo: {},
      ending: false,
      result: false,
      disabledSortable: false,
      editHeader: false,
      SelectStepsCopy: false,
      copyStep: [],
      allCopyStepChecked: false,
      deleteStep: [],
      SelectStepsDelete: false,
      allDeleteStepChecked: false,
      stepTypeMode: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { edit, addBranchEdit, data } = this.props;
    const {
      gotoBranchId, steps, copyStep, editHeader, stepConst,
    } = this.state;

    if (steps !== prevState.steps) {
      steps.forEach((step, i) => {
        // eslint-disable-next-line no-param-reassign
        step.ind = i;
      });
    }
    const copyStepFromStorage = JSON.parse(localStorage.getItem('copyStep'));
    if (JSON.stringify(copyStepFromStorage) !== JSON.stringify(copyStep)) {
      this.setState({
        copyStep: copyStepFromStorage,
      });
    }
    if (
      JSON.stringify(prevState.steps) !== JSON.stringify(steps)
      || prevState.editHeader !== editHeader
      || Number(prevState.gotoBranchId) !== Number(gotoBranchId)
    ) {
      const isStepsEqual = isEqual(steps, stepConst);

      const newEdit = !isStepsEqual
        || editHeader
        || Number(gotoBranchId) !== Number(data.gotoBranchId);
      if (newEdit !== addBranchEdit) {
        edit(newEdit);
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.stepTypes !== state.stepsType) {
      return { stepsType: props.stepTypes };
    }
    return null;
  }

  defStepType() {
    const { result, ending } = this.state;

    if (result) {
      return 5;
    } if (ending) {
      return 5;
    }
    return 1;
  }

  disabledSortableAction = (val) => {
    this.setState({
      disabledSortable: val,
    });
  };

  errorAlert = (error) => {
    this.setState({
      errorMsg: error,
    });
    setTimeout(() => {
      this.errorAlertClose();
    }, 3000);
  };

  errorAlertClose = () => {
    this.setState({
      errorMsg: null,
    });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { steps } = this.state;

    this.setState({
      steps: arrayMove(steps, oldIndex, newIndex),
      activeSteps: `step-${newIndex}`,
    });
  };

  updateSteps = (index, value) => {
    const { steps } = this.state;

    const newSteps = steps.slice();
    newSteps[index] = value;
    this.setState({
      steps: newSteps,
      ...this.getCheckChoiceStates(newSteps),
    }, this.updateStats());
  };

  handleChangeSteps = (value, stepIndex, key) => {
    const { steps } = this.state;
    const stepsCopy = steps.slice();
    const obj = {
      [key]: value,
    };
    stepsCopy[stepIndex] = { ...stepsCopy[stepIndex], ...obj };

    if (Number(stepsCopy[stepIndex].stepTypeId) !== StepTypes.Remember) {
      this.removeStepAction(stepsCopy[stepIndex]);
    }

    if (Number(stepsCopy[stepIndex].stepTypeId) !== StepTypes.Check) {
      this.removeStepCheck(stepsCopy[stepIndex]);
    }

    this.setState({
      steps: stepsCopy,
      ...this.getCheckChoiceStates(stepsCopy),
    });
  };

  addStepObject = (stepIndex, objectName, newObject) => {
    const { steps } = this.state;
    const stepCopy = { ...steps[stepIndex] };

    stepCopy[objectName] = newObject;
    this.updateSteps(stepIndex, stepCopy);
  };

  removeStepObject = (step, objectNames) => {
    if (!objectNames) {
      return;
    }

    for (let i = 0; i < objectNames.length; i++) {
      const objectName = objectNames[i];
      if (step[objectName]) {
        // eslint-disable-next-line no-param-reassign
        delete step[objectName];
      }
    }
  };

  handleChangeStepObject = (objectName, values, stepIndex) => {
    const { steps } = this.state;
    const stepCopy = { ...steps[stepIndex] };
    const { value } = values[0];
    // TODO: REWORK THIS
    if (objectName === 'check') {
      const { check } = stepCopy;
      const isMemoryLink = isMemorySlotVariableLink(value);
      if (isMemoryLink) {
        const operator = CheckOperator[check.operator];
        const isMultiSelect = operator?.isMultiSelect ?? false;
        if (isMultiSelect) {
          stepCopy.switch = value.variableId.map((variableId) => {
            const existingValue = stepCopy.switch?.find((sw) => sw.value === variableId);
            if (existingValue) {
              return existingValue;
            }
            return {
              value: variableId,
              gotoBranchId: 0,
            };
          });
        }
      }
    }

    let tempObj = {};
    if (stepCopy[objectName]) {
      tempObj = { ...stepCopy[objectName] };
    }

    values.forEach((valueObj) => {
      tempObj[valueObj.name] = valueObj.value;
    });
    stepCopy[objectName] = tempObj;
    this.updateSteps(stepIndex, stepCopy);
  };

  addStepAction = (stepIndex, variableId, variable, type, value) => {
    const actionObj = {
      variableId, variable, type, value,
    };
    this.addStepObject(stepIndex, 'action', actionObj);
  };

  removeStepAction = (step) => {
    this.removeStepObject(step, ['action']);
  };

  handleChangeStepAction = (values, stepIndex) => {
    this.handleChangeStepObject('action', values, stepIndex);
  };

  addStepCheck = (stepIndex, variableId, operatorName, value) => {
    const checkObj = {
      variableId: Number(variableId), operator: operatorName, value,
    };
    this.addStepObject(stepIndex, 'check', checkObj);
  };

  removeStepCheck = (step) => {
    this.removeStepObject(step, ['check', 'switch']);
  };

  handleChangeStepCheck = (values, stepIndex) => {
    this.handleChangeStepObject('check', values, stepIndex);
  };

  handleChangeStepSwitch = (gotoBranchId, stepIndex, switchIndex) => {
    const { steps } = this.state;
    const stepData = { ...steps[stepIndex] };

    const switchValues = stepData.switch ? [...stepData.switch] : [];

    const isMultiSelect = CheckOperator[stepData.check.operator]?.isMultiSelect ?? false;

    if (!switchValues[SWITCH_INDEX_TYPE.TRUE] && !isMultiSelect) {
      switchValues[SWITCH_INDEX_TYPE.TRUE] = {
        value: true,
        gotoBranchId: 0,
      };
    }
    if (!switchValues[SWITCH_INDEX_TYPE.FALSE] && !isMultiSelect) {
      switchValues[SWITCH_INDEX_TYPE.FALSE] = {
        value: false,
        gotoBranchId: 0,
      };
    }

    switchValues[switchIndex].gotoBranchId = gotoBranchId;
    stepData.switch = switchValues;
    this.updateSteps(stepIndex, stepData);
  };

  addStep = (index, obj, replace) => {
    const { steps } = this.state;
    const newSteps = steps.slice();
    const { data } = this.props;

    const newStep = obj || {
      id: '',
      stepTypeId: this.defStepType(),
      text: null,
      characterId: null,
      characterRelationship: null,
      characterExpressionId: null,
      branchId: data.id,
      answers: [],
      data: [],
    };
    if (index !== undefined) {
      if (replace) {
        newSteps.splice(index, 1, newStep);
      } else {
        newSteps.splice(index + 1, 0, newStep);
      }
    } else {
      newSteps.push(newStep);
    }
    this.setState({
      activeSteps: `step-${index !== undefined ? index + 1 : newSteps.length - 1}`,
      steps: newSteps,
      ...this.getCheckChoiceStates(newSteps),
    });
  };

  deleteStep = (i) => {
    const newSteps = [];
    const { steps } = this.state;
    steps.forEach((a, index) => {
      if (!i.includes(index)) {
        newSteps.push(a);
      }
    });
    this.setState({
      steps: newSteps,
      deleteStep: [],
      SelectStepsDelete: false,
      allDeleteStepChecked: false,
      ...this.getCheckChoiceStates(newSteps),
    });
  };

  activeSteps = (arg) => {
    this.setState({
      activeSteps: arg,
    });
  };

  updateStats = () => {
    const { steps, statsInfo, currStatsInfo } = this.state;
    const newStatsInfo = { ...statsInfo };
    steps.forEach((el) => {
      if (Number(el.stepTypeId) === 3) {
        el.answers.forEach((ans) => {
          ans.requires.forEach((req) => {
            newStatsInfo[Number(req.requireTypeId)] += 1;
          });
        });
      }
    });
    if (JSON.stringify(newStatsInfo) !== JSON.stringify(currStatsInfo)) {
      this.setState({
        currStatsInfo: newStatsInfo,
      });
    }
  };

  initEditBranch = (val) => {
    const { modalNext, modalPrev } = this.state;

    if (val === 'prev') {
      this.setState({
        modalPrev: !modalPrev,
      });
    }
    if (val === 'next') {
      this.setState({
        modalNext: !modalNext,
      });
    }
  };

  actionEditBranch = (val) => {
    const { onHide, actionEditBranch } = this.props;

    onHide();
    setTimeout(() => {
      actionEditBranch(val);
    }, 50);
  };

  componentDidMount() {
    const { data } = this.props;

    if (data !== null && data.id) {
      this.loadData();
    }

    if (data === null || !data.id) {
      this.addStories(data || {}, true);
    }
  }

  reLoadCharacters = () => {
    const { storyId } = this.props;

    api.get(`/v1/stories/${storyId}/characters`)
      .then((res) => {
        this.setState({
          characters: res.data.characters,
        });
      })
      .catch((error) => {
        this.errorAlert(error);
      });
  };

  loadData() {
    const { story, storyId, data } = this.props;
    this.setState({
      loading: true,
    });

    async.parallel({
      locations: (callback) => {
        api.get(`/v1/books/${story.book.id}/locations`)
          .then((res) => {
            callback(null, res.data.locations);
          }).catch((error) => {
            callback(error, null);
          });
      },
      characters: (callback) => {
        api.get(`/v1/stories/${storyId}/characters`)
          .then((res) => {
            callback(null, res.data.characters);
          }).catch((error) => {
            callback(error, null);
          });
      },
      expressions: (callback) => {
        api.get('/v1/characters/expressions')
          .then((res) => {
            callback(null, res.data.expressions);
          }).catch((error) => {
            callback(error, null);
          });
      },
      tags: (callback) => {
        api.get('/v1/answers/tags/types')
          .then((res) => {
            callback(null, res.data.types);
          }).catch((error) => {
            callback(error, null);
          });
      },
      modifiers: (callback) => {
        api.get('/v1/answers/modifiers/types')
          .then((res) => {
            callback(null, res.data.types);
          }).catch((error) => {
            callback(error, null);
          });
      },
      stats: (callback) => {
        api.get('/v1/stats/types')
          .then((res) => {
            callback(null, res.data.types);
          }).catch((error) => {
            callback(error, null);
          });
      },
      answerType: (callback) => {
        api.get('/v1/answers/types')
          .then((res) => {
            callback(null, res.data.types);
          }).catch((error) => {
            callback(error, null);
          });
      },
      branchData: (callback) => {
        if (data.id) {
          api.get(`/v1/stories/${storyId}/branches/${data.id}`)
            .then((res) => {
              callback(null, res.data.branch);
            }).catch((error) => {
              callback(error, null);
            });
        } else {
          callback(null, null);
        }
      },
      storyInfo: (callback) => {
        api.get(`/v1/stories/${storyId}/info?statonly=1`)
          .then((res) => {
            callback(null, res.data.info);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, res) => {
      try {
        if (err) {
          this.errorAlert(err);
        } else {
          const reqs = []; const
            traits = [];
          for (let i = 0; i < res.stats.length; i++) {
            const item = res.stats[i];
            if (item.trait === true) traits.push(item);
            if (item.require === true) reqs.push(item);
          }
          const statsInfo = { ...res.storyInfo.stats };
          res.branchData.steps.forEach((step) => {
            if (step.stepTypeId === 3) {
              step.answers.forEach((ans) => {
                ans.requires.forEach((req) => {
                  statsInfo[req.requireTypeId] -= 1;
                });
              });
            }
          });

          const steps = res.branchData?.steps != null ? res.branchData.steps : [];
          const stepTypeMode = res.branchData?.stepTypeMode ?? StepTypeMode.Regular;

          this.setState({
            requiresTypes: reqs,
            traitsTypes: traits,
            characters: res.characters,
            locations: res.locations,
            characterExpression: res.expressions,
            tagsTypes: res.tags,
            modifiersTypes: res.modifiers,
            steps,
            stepConst: steps,
            locationId: res.branchData && res.branchData.locationId !== null ? Number(res.branchData.locationId) : '',
            analytics: res.branchData && res.branchData.analytics ? res.branchData.analytics : null,
            addStep: false,
            loading: false,
            data: res.branchData,
            answerType: res.answerType,
            statsInfo,
            currStatsInfo: res.storyInfo.stats,
            stepTypeMode,
            ...this.getCheckChoiceStates(steps),
          });

          // Focus on the text step if possible
          const { editStepId } = data;
          if (editStepId) {
            const stepIndex = steps.findIndex((step) => step.id === editStepId);
            if (stepIndex !== -1) {
              const stepTextElement = document.getElementById(`stepText-${stepIndex}`);
              if (stepTextElement) {
                stepTextElement.focus();
              }
            }
          }
        }
      } catch (e) {
        this.errorAlert(e);
      }
    });
  }

  updateLocation = () => {
    const { story } = this.props;

    api.get(`/v1/books/${story.book.id}/locations`)
      .then((res) => {
        this.setState({
          locations: res.data.locations,
        });
      });
  };

  addStories(arr, validated) {
    const {
      lastLocation,
      update,
      data,
      storyId,
      onError,
      onLocationSelected,
      onBranchUpdate,
      onBranchCreate,
    } = this.props;

    // eslint-disable-next-line no-param-reassign
    arr = JSON.parse(JSON.stringify(arr, replacer, 2));

    if (arr && arr.steps && arr.steps.length > 0
        && parseInt(arr.steps[0].stepTypeId, 10) !== 3
        && (arr.steps[0].answers || (arr.steps[0].answers
        && arr.steps[0].answers.length > 0))
    ) {
      // eslint-disable-next-line no-param-reassign
      delete arr.steps[0].answers;
    }

    if (validated === true) {
      if (data !== null && data.id) {
        onLocationSelected(arr.locationId || null);
        this.setState({
          saveLoading: true,
        });
        api.put(`/v1/stories/${storyId}/branches/${data.id}`, arr)
          .then(() => {
            onBranchUpdate(data.id);
            this.setState({
              saveLoading: false,
            });
          })
          .catch((error) => {
            this.errorAlert(error);
            this.setState({
              saveLoading: false,
            });
          });
      } else {
        this.setState({
          addLoading: true,
        });
        if (!arr.locationId && lastLocation) {
          // eslint-disable-next-line no-param-reassign
          arr.locationId = lastLocation;
        }
        api.post(`/v1/stories/${storyId}/branches`, arr)
          .then((res) => {
            localStorage.setItem(`lastEdit-${storyId}`, res.data.branch.id);
            onBranchCreate({ id: res.data.branch.id, arr });
          })
          .catch((error) => {
            this.setState({
              addLoading: false,
            });
            if ((data === null || !data.id) && onError) {
              onError(error);
              update();
            } else {
              this.errorAlert(error);
            }
          });
      }
    }
  }

  updateLive(arr, validated) {
    // eslint-disable-next-line no-param-reassign
    arr = JSON.parse(JSON.stringify(arr, replacer, 2));

    if (validated === true) {
      this.setState({
        saveLoading: true,
      });
      const { update, storyId, data } = this.props;
      const putUrl = `/v1/stories/${storyId}/branches/${data.id}/live`;
      api.put(putUrl, arr)
        .then(() => {
          update();
          this.setState({
            saveLoading: false,
          });
        })
        .catch((error) => {
          this.errorAlert(error);
          this.setState({
            saveLoading: false,
          });
        });
    }
  }

  formSlotValidate = (slotValue, slotId, actionType) => {
    const { memoryBank } = this.props;

    const foundSlot = memoryBank.find((slot) => slot.id === Number(slotId));
    const memoryLinkValue = getMemorySlotVariableLink(slotValue);

    if (memoryLinkValue) {
      const { type, variableId } = memoryLinkValue;
      switch (type) {
        case MemoryCheckValueType.Variable: {
          const comparedVariable = memoryBank.find((memory) => memory.id === Number(variableId));
          if (!comparedVariable) {
            showToast({ textMessage: 'Compared variable not found', variant: 'warning' });
            return false;
          }
          if (!foundSlot) {
            showToast({ textMessage: `Memory with id ${slotId} not found`, variant: 'warning' });
            return false;
          }
          const isComparedVariableTypeValid = comparedVariable && comparedVariable.type === foundSlot.type;
          if (!isComparedVariableTypeValid) {
            showToast({ textMessage: `Slot value type is not valid in ${comparedVariable?.name}`, variant: 'warning' });
            return false;
          }
          break;
        }
        case MemoryCheckValueType.VariableArray: {
          if (!Array.isArray(variableId)) {
            showToast({ textMessage: 'Variable array should be an array', variant: 'warning' });
            return false;
          }
          if (variableId.length < 2) {
            showToast({ textMessage: 'Variable array should have at least TWO element', variant: 'warning' });
            return false;
          }
          break;
        }
        default:
          break;
      }
      return true;
    }

    if (!foundSlot) {
      showToast({ textMessage: `Memory with id ${slotId} not found`, variant: 'warning' });
      return false;
    }

    if (actionType === BranchStepMemoryActionType.View) {
      const availableActionTypes = Object.values(BranchStepMemoryActionType);
      if (foundSlot.showIn.length === 0) {
        showToast({ textMessage: `Memory visibility disabled in Memory Bank for ${foundSlot.name}`, variant: 'warning' });
        return false;
      }
      return availableActionTypes.includes(actionType);
    }

    if (foundSlot.type === MemoryType.Number) {
      const isNumbersOnly = /^-?\d+$/.test(slotValue);
      if (!isNumbersOnly) {
        showToast({ textMessage: 'Slot value should be a number', variant: 'warning' });
      }
      return isNumbersOnly;
    }
    return true;
  };

  formActionValidate = (stepObj) => {
    const { action } = stepObj;

    if (!action.value) {
      showToast({
        textMessage: 'Action value is empty',
      });
      return false;
    }
    const isSlotValid = this.formSlotValidate(action.value, action.variableId, action.type);
    return isSlotValid;
  };

  formSwitchValidate = (stepObj) => {
    const checkOperator = stepObj?.check?.operator;
    if (!checkOperator) {
      showToast({ textMessage: 'Check operator is empty', variant: 'warning' });
      return false;
    }

    const operator = CheckOperator[checkOperator];
    if (stepObj.switch.length !== 2 && operator?.isMultiSelect !== true) {
      showToast({ textMessage: 'Switch should have 2 cases' });
      return false;
    }

    let isValid = true;
    stepObj.switch.forEach((switchObj) => {
      if (!switchObj.gotoBranchId) {
        showToast({
          textMessage: 'Goto branch is empty',
        });
        isValid = false;
      }
    });
    return isValid;
  };

  formCheckValidate = (stepObj) => {
    if (!stepObj.check.operator) {
      showToast({ textMessage: 'Check operator is empty', variant: 'warning' });
      return false;
    }
    const isMultiSelect = CheckOperator[stepObj.check.operator]?.isMultiSelect ?? false;

    if (isMultiSelect) {
      if (!stepObj.switch || stepObj.switch.length < 2) {
        showToast({ textMessage: 'At least 2 memory options must be selected', variant: 'warning' });
        return false;
      }
    } else if (!stepObj.check.variableId) {
      showToast({ textMessage: 'Check variable is empty', variant: 'warning' });
      return false;
    }

    return this.formSlotValidate(
      stepObj.check.value,
      stepObj.check.variableId,
    );
  };

  formAnswerRequirementsValidate = (answersList) => {
    const { user } = this.props;
    let isSlotValid = true;
    let numOfRequirements = 0;
    const numOfAnswers = answersList.length;

    answersList.forEach((answer) => {
      if (answer.requirement && answer.requirement.check) {
        numOfRequirements += 1;

        // check if comparing value is ok
        const isAnswerSlotValid = this.formSlotValidate(
          answer.requirement.check.value,
          answer.requirement.check.variableId,
        );

        if (!isAnswerSlotValid) {
          isSlotValid = false;
        }
      }
    });

    if (!isSlotValid) {
      showToast({
        textMessage: 'Requirements parameters are invalid',
      });
    }

    const isAdmin = user && user.role === 'admin';
    // prevent having all answers with requirements (one needs to be always visible);
    const isAnswerWithoutRequirement = !(numOfRequirements === numOfAnswers) || isAdmin;
    if (!isAnswerWithoutRequirement) {
      showToast({
        textMessage: 'No Free Answer without Requirements',
      });
    }

    return isSlotValid && isAnswerWithoutRequirement;
  };

  formValidated = (validateValue) => {
    const { limits, memoryBank } = this.props;
    const {
      steps,
      stepTypeMode,
      title,
      description,
    } = validateValue;
    const isEliminationType = stepTypeMode === StepTypeMode.Elimination;
    const isChoiceStep = isDecisionStep(validateValue);
    const hasSteps = steps && steps.length > 0;

    if (title && (title.length > Number(limits.branch_title_max.value))) {
      showToast({ textMessage: `Title is too long. Max length is ${limits.branch_title_max.value} characters`, variant: 'warning' });
      return false;
    }

    if (description && (description.length > Number(limits.branch_description_max.value))) {
      showToast({ textMessage: `Description is too long. Max length is ${limits.branch_description_max.value} characters`, variant: 'warning' });
      return false;
    }

    if (!hasSteps) {
      // Request from Jess. Allow save w/o steps
      return true;
    }

    const hasAnswers = steps.some((step) => step.answers && step.answers.length > 0);
    if (!hasAnswers && isChoiceStep) {
      showToast({ textMessage: 'No answers in the step', variant: 'warning' });
      return false;
    }

    // Check for correct answers in Elimination Mode
    if (isEliminationType) {
      for (let i = 0; i < steps.length; i++) {
        const step = steps[i];
        const { answers } = step;
        if (!answers) {
          showToast({ textMessage: 'No Answers in Elimination Mode', variant: 'warning' });
          return false;
        }
        const isCorrectAnswerExist = answers.some((answer) => answer.eliminationResultType === EliminationAnswerResultType.Correct);
        if (!isCorrectAnswerExist) {
          showToast({ textMessage: 'No Correct Answer in Elimination Mode', variant: 'warning' });
          return false;
        }
      }
    }

    for (let i = 0; i < steps.length; i++) {
      const step = steps[i];
      if (!step) {
        showToast({ textMessage: `Incorrect data in step ${i}` });
        return false;
      }
      const stepTypeId = Number(step.stepTypeId);

      if (step.text?.length > Number(limits[`step_${stepTypeId}_text_max`]?.value)) {
        return false;
      }

      const errorsInStepText = checkUsedMemoriesInText(step.text ?? '', memoryBank);
      if (errorsInStepText.length > 0) {
        showToast({
          textMessage: `Memory slot(s) ${errorsInStepText.join(', ')} used in the text are not defined in the Memory Bank`,
          variant: 'warning',
          timeout: 7000,
        });
        return false;
      }

      if (step.answers && step.answers.length > 0) {
        for (let j = 0; j < step.answers.length; j++) {
          const answer = step.answers[j];
          if (answer.text && answer.text.length > Number(limits.answer_text_max.value)) {
            return false;
          }
          if (answer.data && answer.data.length > 0) {
            for (let k = 0; k < answer.data.length; k++) {
              const data = answer.data[k];
              if (data.key && data.key.length > Number(limits.data_key_max.value)) {
                return false;
              }
              if (data.value && data.value.length > Number(limits.data_value_max.value)) {
                return false;
              }
            }
          }

          const errorsInAnswerText = checkUsedMemoriesInText(answer.text ?? '', memoryBank);
          if (errorsInAnswerText.length > 0) {
            showToast({
              textMessage: `Memory slot(s) ${errorsInAnswerText.join(', ')} used in the answer text are not defined in the Memory Bank`,
              variant: 'warning',
              timeout: 7000,
            });
            return false;
          }
        }

        if (!this.formAnswerRequirementsValidate(step.answers)) {
          return false;
        }
      }

      if (step.data && step.data.length > 0) {
        for (let k = 0; k < step.data.length; k++) {
          const data = step.data[k];
          if (data.key && data.key.length > Number(limits.data_key_max.value)) {
            return false;
          }
          if (data.value && data.value.length > Number(limits.data_value_max.value)) {
            return false;
          }
        }
      }
      if (step.action && !this.formActionValidate(step)) {
        return false;
      }
      if (step.switch && !this.formSwitchValidate(step)) {
        return false;
      }
      if (step.check && !this.formCheckValidate(step)) {
        return false;
      }

      if (stepTypeId === StepTypes.Achieve && !step?.achievementId) {
        showToast({ textMessage: `Achievement is not selected in step ${i}` });
        return false;
      }
    }

    return true;
  };

  handleSubmit(event) {
    const { restrictedEdit } = this.props;

    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const formValues = serialize(form, { hash: true, disabled: false });

    // We need pass '*.value' as object but forms don't support values as objects
    // Here we change '*.value' to object
    formValues.steps = formValues?.steps?.map((step) => {
      const newStep = { ...step };
      if (newStep.check) {
        const newValue = isMemorySlotVariableLink(newStep.check.value)
          ? parseMemorySlotVariableLink(newStep.check.value)
          : newStep.check.value;
        if (newValue) {
          newStep.check.value = newValue;
          return newStep;
        }
      }

      if (newStep.answers) {
        newStep.answers = newStep.answers.map((answer) => {
          const newAnswer = { ...answer };
          if (newAnswer.requirement?.check?.value !== undefined) {
            const newValue = isMemorySlotVariableLink(newAnswer.requirement.check.value)
              ? parseMemorySlotVariableLink(newAnswer.requirement.check.value)
              : newAnswer.requirement.check.value;
            if (newValue) {
              newAnswer.requirement.check.value = newValue;
              return newAnswer;
            }
          }
          return newAnswer;
        });
      }

      // Convert action string value to array
      if (newStep.action && newStep.action.type === RememberActionType.VIEW.name) {
        const newActionValue = newStep.action.value?.split(',');
        if (newActionValue) {
          newStep.action.value = newActionValue;
          return newStep;
        }
        showToast({ textMessage: 'Error parsing action value' });
      }

      return step;
    });

    if (validated === false || !this.formValidated(formValues)) {
      event.stopPropagation();
      return;
    }

    if (restrictedEdit) {
      this.updateLive(formValues, validated);
    } else {
      this.addStories(formValues, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  getCheckChoiceStates(steps) {
    return {
      choice: isStepTypeIdExist(steps, StepTypes.Choice),
      isCheckStep: isStepTypeIdExist(steps, StepTypes.Check),
      ending: isStepTypeIdExist(steps, StepTypes.Ending),
      result: isStepTypeIdExist(steps, StepTypes.Result),
    };
  }

  pasteStep = () => {
    const { result, steps, ending } = this.state;

    const data = getStepData();
    if (data.length < 1) {
      return;
    }
    data.forEach((a) => {
      const obj = prepareForCopy(a);

      if ((Number(obj.stepTypeId) === 3) && (steps.length > 0)) {
        this.errorAlert({ response: { data: { error: 'Choice step should be the only one in the node' } } });
        return;
      }
      if ((Number(obj.stepTypeId) === 5 || Number(obj.stepTypeId) === 6) && (!ending && !result)) {
        this.errorAlert({ response: { data: { error: 'Outro/ending node must only have Ending steps' } } });
        return;
      }
      if ((Number(obj.stepTypeId) !== 5 && Number(obj.stepTypeId) !== 6) && (ending || result)) {
        this.errorAlert({ response: { data: { error: 'Outro/ending node must only have Ending steps' } } });
        return;
      }
      setTimeout(() => {
        this.addStep(undefined, obj, false);
      }, 100);
    });
  };

  handleChangeStepTypeMode = (value) => {
    this.setState({ stepTypeMode: value });
  };

  render() {
    const {
      user,
      disabled,
      show,
      limits,
      restrictedEdit,
      modeEdit,
      onHide,
      data: dataFromProps,
      storyId,
      branchesGroup,
      branch,
      story,
      memoryBank,
    } = this.props;
    const {
      validated,
      gotoBranchId,
      editHeader,
      stepsType,
      copyStep,
      data,
      saveLoading,
      activeSteps,
      characters,
      currStatsInfo,
      errorMsg,
      deleteStep,
      tagsTypes,
      modalPrev,
      locations,
      ending,
      result,
      SelectStepsDelete,
      traitsTypes,
      locationId,
      modalNext,
      characterExpression,
      requiresTypes,
      modifiersTypes,
      addLoading,
      loading,
      disabledSortable,
      defaultValueDescription,
      choice,
      isCheckStep,
      steps,
      SelectStepsCopy,
      allCopyStepChecked,
      answerType,
      defaultValueTitle,
      allDeleteStepChecked,
      stepTypeMode,
    } = this.state;

    if (addLoading === true) {
      return null;
    }

    return (
      <>
        <ErrorAlert error={errorMsg} close={this.errorAlertClose} />

        <Modal
          show={show}
          onHide={onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          className={cs('modalBig', 'modal-dialog', modalNext === true ? 'modalNext-active' : '', modalPrev ? 'modalPrev-active' : '')}
          centered
          backdrop="static"
          keyboard={false}
        >

          <Spinner
            animation="border"
            variant="primary"
            className={loading !== false ? 'loadingSpinner justify-content-center' : 'd-none '}
          />

          <Modal.Header closeButton className="mb-0 smf-header">

            <BranchModalPrevNext
              {...this.props}
              storyId={storyId}
              data={data}
              type="prev"
              characterExpression={characterExpression}
              characters={characters}
              actionEditBranch={this.actionEditBranch}
              initEditBranch={this.initEditBranch}
            />

          </Modal.Header>

          <Modal.Body className="p-0">

            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => {
                if (!disabled || restrictedEdit) {
                  this.handleSubmit(e);
                }
              }}
              className={cs('sf-modal', loading !== false ? 'd-none' : null)}
            >

              <div className="sf-container">
                <AddBranchHead
                  user={user}
                  title={defaultValueTitle}
                  disabled={!!(saveLoading || disabled)}
                  description={defaultValueDescription}
                  storyId={storyId}
                  story={story}
                  locationId={locationId}
                  locations={locations}
                  limits={limits}
                  activeStepsFunc={this.activeSteps}
                  editHeader={editHeader}
                  updateLocation={this.updateLocation}
                  update={(e) => {
                    this.setState({
                      editHeader: e,
                    });
                  }}
                  restrictedEdit={restrictedEdit}
                />

                <div
                  id={SCROLLABLE_STEP_FORM_ELEMENT_ID}
                  className="sf-content"
                >
                  <Form.Check
                    id="step-type-mode"
                    name="stepTypeMode"
                    type="hidden"
                    value={stepTypeMode ?? StepTypeMode.Regular}
                  />

                  {SelectStepsCopy && (
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                    <div
                      onClick={(e) => {
                        this.setState({
                          allCopyStepChecked: !allCopyStepChecked,
                        });
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      style={{
                        width: '15em',
                        marginLeft: '1.55em',
                      }}
                    >
                      <Form.Check
                        custom
                        autoFocus
                        as="input"
                        checked={allCopyStepChecked}
                        onMouseOver={(e) => {
                          this.disabledSortableAction(e);
                        }}
                        onChange={
                          (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }
                        }
                        type="checkbox"
                        id="allCopyStep"
                        name="allCopyStep"
                        label="Select All Steps to Copy"
                        style={{ margin: '.25em' }}
                      />
                    </div>
                  )}

                  {SelectStepsDelete && (
                  // eslint-disable-next-line max-len
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                    <div
                      onClick={(e) => {
                        const all = !allDeleteStepChecked;
                        const newDeleteStep = [];
                        if (all) {
                          steps.map((a, i) => newDeleteStep.push(i));
                        }
                        this.setState({
                          deleteStep: newDeleteStep,
                          allDeleteStepChecked: all,
                        });
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      style={{
                        width: '15em',
                        marginLeft: '1.55em',
                      }}
                    >
                      <Form.Check
                        custom
                        autoFocus
                        as="input"
                        checked={allDeleteStepChecked}
                        onMouseOver={(e) => {
                          this.disabledSortableAction(e);
                        }}
                        onChange={
                          (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }
                        }
                        type="checkbox"
                        id="allDeleteStep"
                        name="allDeleteStep"
                        label="Select All Steps to Delete"
                        style={{ margin: '.25em' }}
                      />
                    </div>
                  )}
                  { !loading && (
                    <ContextProvider
                      branches={branch}
                      currentBranchId={dataFromProps.id}
                      characterExpression={characterExpression}
                      tagsTypes={tagsTypes}
                      modifiersTypes={modifiersTypes}
                      characters={characters}
                      stepsType={stepsType}
                      requiresTypes={requiresTypes}
                      traitsTypes={traitsTypes}
                      answerType={answerType}
                      currStatsInfo={currStatsInfo}
                      storyRecommendations={story.recommendations}
                      memoryBankSlots={memoryBank}
                      limits={limits}
                      stepTypeMode={stepTypeMode}
                      handleChangeStepTypeMode={this.handleChangeStepTypeMode}
                      story={story}
                    >
                      <SortableListSteps
                        distance={2}
                        user={user}
                        items={steps}
                        limits={limits}
                        disabledSortable={disabledSortable || restrictedEdit}
                        disabledSortableAction={this.disabledSortableAction}
                        onSortEnd={this.onSortEnd}
                        helperClass="sort-item"
                        errorAlert={this.errorAlert}
                        story={story}
                        totalSteps={count(steps)}
                        deleteStep={this.deleteStep}
                        addStep={this.addStep}
                        activeSteps={activeSteps}
                        activeStepsFunc={this.activeSteps}
                        handleChangeSteps={this.handleChangeSteps}
                        updateStep={this.updateSteps}
                        isIntro={dataFromProps && dataFromProps.title === 'intro'}
                        ending={ending}
                        result={result}
                        defStepType={this.defStepType()}
                        updateCharacters={(val) => {
                          this.reLoadCharacters(val);
                        }}
                        copyStep={copyStep}
                        updateCopyStep={(selectedSteps) => {
                          this.setState({
                            copyStep: selectedSteps,
                            allCopyStepChecked: steps.length === selectedSteps.length,
                          });
                        }}
                        updateDeleteStep={(stepIndex, isCheck) => {
                          const newStepIndexesToDelete = isCheck
                            ? [...deleteStep, stepIndex]
                            : deleteStep.filter(
                              (stepIndexToDelete) => stepIndexToDelete !== stepIndex,
                            );
                          this.setState({
                            deleteStep: newStepIndexesToDelete,
                            allDeleteStepChecked: steps.length === newStepIndexesToDelete.length,
                          });
                        }}
                        deleteStepVal={deleteStep}
                        SelectStepsCopy={SelectStepsCopy}
                        SelectStepsDelete={SelectStepsDelete}
                        restrictedEdit={restrictedEdit}
                        allCopyStepChecked={allCopyStepChecked}
                        allDeleteStepChecked={allDeleteStepChecked}
                        branches={branch}
                        addStepAction={this.addStepAction}
                        removeStepAction={this.removeStepAction}
                        handleChangeStepAction={this.handleChangeStepAction}
                        addStepCheck={this.addStepCheck}
                        removeStepCheck={this.removeStepCheck}
                        handleChangeStepCheck={this.handleChangeStepCheck}
                        handleChangeStepSwitch={this.handleChangeStepSwitch}
                      />
                    </ContextProvider>
                  )}

                  <AddStep
                    steps={steps}
                    restrictedEdit={restrictedEdit}
                    choice={choice}
                    addStep={this.addStep}
                    pasteStep={this.pasteStep}
                    copyStep={copyStep}
                    SelectStepsCopy={SelectStepsCopy}
                    updateCopyStep={(val) => {
                      this.setState({
                        copyStep: val,
                      });
                    }}
                    updateDeleteStep={(val) => {
                      this.setState({
                        deleteStep: val,
                      });
                    }}
                    handleAddSteps={() => {
                      clearStepData();
                      this.setState({
                        SelectStepsCopy: !SelectStepsCopy,
                        SelectStepsDelete: false,
                        disabledSortable: false,
                        allCopyStepChecked: false,
                        allDeleteStepChecked: false,
                        deleteStep: [],
                        copyStep: [],
                      });
                    }}
                    SelectStepsDelete={SelectStepsDelete}
                    deleteStep={deleteStep}
                    deleteStepFunc={this.deleteStep}
                    handleDelete={() => {
                      localStorage.setItem('copyStep', JSON.stringify([]));
                      this.setState({
                        SelectStepsCopy: false,
                        SelectStepsDelete: !SelectStepsDelete,
                        disabledSortable: false,
                        allCopyStepChecked: false,
                        allDeleteStepChecked: false,
                        deleteStep: [],
                        copyStep: [],
                      });
                    }}
                    isCheckStep={isCheckStep}
                  />
                </div>

                <div className="sf-footer">
                  <Form.Row className="w-100">
                    <Form.Group as={Col} md="9" className="mb-0 pb-0" controlId="gotoBranchId">
                      <Row>
                        <Form.Label column>
                          {
                            stepTypeMode === StepTypeMode.Regular
                              ? 'Goto node'
                              : 'Goto correct node'
                          }
                        </Form.Label>
                        <Col md={9}>
                          <CustomSelect
                            value={gotoBranchId || ''}
                            disabled={(choice && stepTypeMode === StepTypeMode.Regular)
                              || ending
                              || result
                              || restrictedEdit
                              || isCheckStep}
                            name="gotoBranchId"
                            args={[
                              { id: null, title: '' },
                              { id: -1, title: '[Create New Node]' },
                              ...branch,
                            ]}
                            data={dataFromProps.id}
                            onFocus={noop}
                            onChange={(e) => {
                              this.setState({
                                gotoBranchId: e,
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group as={Col} className="mb-0 pb-0 text-right">
                      <Button
                        className="mx-1"
                        size="sm"
                        variant="secondary"
                        onClick={() => onHide()}
                      >
                        Cancel
                      </Button>

                      <Button
                        size="sm"
                        type="submit"
                        variant="primary"
                        disabled={
                          !!((
                            saveLoading
                              || disabled
                          )
                            && !restrictedEdit
                          )
                          || !modeEdit
                          || PremiumIpDisabledEdit(user.role, story.book, branchesGroup)
                          || PremiumIpDisabledApprovedEdit(user.role, story.book)
                        }
                      >
                        {saveLoading && (
                          <Spinner
                            variant="primary"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        {restrictedEdit ? 'Update Live' : 'Save'}
                      </Button>
                    </Form.Group>
                  </Form.Row>
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer className="smf-footer">
            <BranchModalPrevNext
              {...this.props}
              storyId={storyId}
              data={data}
              type="next"
              characterExpression={characterExpression}
              characters={characters}
              actionEditBranch={this.actionEditBranch}
              initEditBranch={this.initEditBranch}
            />
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
